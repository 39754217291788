import "./global/polyfills/RegExp.escape.ts";

import Chainable from "./Chainable.mts";
import UArray from "./UArray.mts";
import UTuple from "./UTuple.mts";

declare global {
  interface String {
    split(splitter: string, limit?: number): [string, ...string[]];
    split(
      splitter: { [Symbol.split](string: string, limit?: number): string[] },
      limit?: number,
    ): [string, ...string[]];
  }
}

namespace UString {
  export type Capitalize<S extends string> =
    S extends `${infer C0}${infer Rest}` ? `${Uppercase<C0>}${Rest}` : S;
  export const capitalize = <S extends string>(string: S): Capitalize<S> =>
    string && (`${string[0]!.toUpperCase()}${string.slice(1)}` as any);

  export type Decapitalize<S extends string> =
    S extends `${infer C0}${infer Rest}` ? `${Lowercase<C0>}${Rest}` : S;
  export const decapitalize = <S extends string>(string: S): Decapitalize<S> =>
    string && (`${string[0]!.toLowerCase()}${string.slice(1)}` as any);

  export type Split<
    S extends string,
    Separator extends string,
    Limit extends number = number,
  > = S extends S ? SplitImpl<S, Separator, UTuple.Of<any, Limit>> : never;
  type SplitImpl<
    S extends string,
    Separator extends string,
    Limit extends any[],
  > = Limit["length"] extends 0
    ? []
    : string extends S
      ? [
          string,
          ...(number extends Limit["length"]
            ? string[]
            : UTuple.Partial<
                UTuple.Of<
                  string,
                  Limit extends [any, ...infer Rest] ? Rest["length"] : never
                >
              >),
        ]
      : S extends `${infer Left}${Separator}${infer Right}`
        ? [
            ...(string extends Left ? [string, ...string[]] : [Left]),
            ...SplitImpl<
              Right,
              Separator,
              number extends Limit["length"]
                ? Limit
                : Limit extends [any, ...infer Rest]
                  ? Rest
                  : never
            >,
          ]
        : [S];

  export const split = <
    S extends string,
    Separator extends string,
    Limit extends number = number,
  >(
    s: S,
    separator: Separator,
    limit?: Limit,
  ) => s.split(separator, limit) as Split<S, Separator, Limit>;

  export const splitAt = <S extends string, const At extends number | number[]>(
    string: S,
    at: At,
  ): At extends number[]
    ? [string, ...UTuple.Of<string, At["length"]>]
    : [string, string] =>
    [at, string.length]
      .flat()
      .map((at, i, arr) => string.slice(i && arr[i - 1], at)) as any;

  export const trim = Chainable.create(
    Chainable.wrapFactory(
      ({ side = "both" as "start" | "end" | "both" }) =>
        (string: string, pattern: string | RegExp = /\s/) => {
          const escaped =
            typeof pattern === "string" ? RegExp.escape(pattern) : pattern;

          if (side !== "end")
            string = string.slice(
              string.match(new RegExp(`^(?:${escaped})*`))![0].length,
            );
          if (side !== "start")
            string = string.slice(
              0,
              -string.match(new RegExp(`(?:${escaped})*$`))![0].length ||
                Infinity,
            );
          return string || "";
        },
    ),
    {
      start: { side: "start" },
      end: { side: "end" },
    },
  );

  export type ResolveGlob<S extends string> =
    S extends `${infer Left}*${infer Right}`
      ? `${Left}${string}${ResolveGlob<Right>}`
      : S;

  export async function replaceAsync(
    string: string,
    regex: RegExp,
    replacer: (
      // ...args: [
      //   match: string,
      //   ...captures: string[],
      //   offset: number,
      //   string: string,
      //   groups: Record<string, string> | undefined,
      // ]
      match: string,
      ...args: any[]
    ) => Promise<string>,
  ) {
    const replacements = await Promise.all(
      Array.from(string.matchAll(regex), (match) =>
        replacer(
          ...(match as [string]),
          match.index,
          match.input,
          match.groups,
        ),
      ),
    );
    let i = 0;
    return string.replace(regex, () => replacements[i++]!);
  }

  export const withAllValues = (
    strings: TemplateStringsArray,
    ...values: any[]
  ) =>
    values.every((n) => n != null)
      ? UArray.join(strings, (i) => values[i])
      : undefined;

  export type AfterPrefix<
    S extends string,
    Prefix extends string,
  > = S extends `${Prefix}${infer R}` ? R : S;
  export const afterPrefix = <S extends string, Prefix extends string>(
    string: S,
    prefix: Prefix,
  ) =>
    (string.startsWith(prefix)
      ? string.slice(prefix.length)
      : string) as AfterPrefix<S, Prefix>;

  export type BeforeSuffix<
    S extends string,
    Suffix extends string,
  > = S extends `${infer R}${Suffix}` ? R : S;
  export const beforeSuffix = <S extends string, Suffix extends string>(
    string: S,
    suffix: Suffix,
  ) =>
    (string.endsWith(suffix)
      ? string.slice(
          0,
          // If suffix is "", -suffix.length will truncate the whole string, must subtract from string.length
          string.length - suffix.length,
        )
      : string) as BeforeSuffix<S, Suffix>;

  export type ReplacePrefix<
    S extends string,
    Prefix extends string,
    Replacement extends string,
  > = S extends `${Prefix}${infer R}` ? `${Replacement}${R}` : S;
  export const replacePrefix = <
    S extends string,
    Prefix extends string,
    Replacement extends string,
  >(
    string: S,
    prefix: Prefix,
    replacement: Replacement,
  ) =>
    (string.startsWith(prefix)
      ? `${replacement}${string.slice(prefix.length)}`
      : string) as ReplacePrefix<S, Prefix, Replacement>;
}

export default UString;
